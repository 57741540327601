@import url(//fonts.googleapis.com/css2?family=Lovers+Quarrel&family=Montserrat);
@tailwind base;
@tailwind components;
@tailwind utilities;

$colorBlack: black;
$letterSpacing: 2px;
$center: center;
body,
html {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  font-display: swap;
  //background-color: #eeeded;
  background-color: #fffefc;
  height: 100%;
}

.typewriter {
  overflow: hidden;
  border-right: 2px solid black;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 1.5s steps(40, end), blink-caret 0.85s step-end infinite;
}

.floating_button {
  position: fixed;
  bottom: 3em;
  right: 4em;
}

.text {
  height: 100%;
  width: 100%;
}

.logo {
  justify-content: $center;
  display: flex;
  align-items: $center;
  padding: 20px !important;
  height: 305px;
  &_name {
    text-align: $center;
    font-size: 170px;
    font-family: "Lovers Quarrel", sans-serif;
    // font-display: swap;
    color: $colorBlack;
    margin: 0px !important;
    & :hover {
      cursor: pointer;
    }
  }
}

.test {
  display: flex;
  overflow-x: scroll;
  width: auto;
}
/** st*/
.mapouter {
  position: relative;
  text-align: right;
  /*width: 90%;*/
  height: 545px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  /*width: 90%;*/
  height: 545px;
}
.gmap_iframe {
  height: 545px !important;
}

.uk-navbar-center {
  justify-content: center;
  height: 120px;
  width: 100%;
  align-items: center;
}

.uk-navbar-nav {
  justify-content: space-evenly;
  width: 45%;
}

/*
height: 120px; banner;
display: flex;
just-content:center pr div uk-navbar-center;
just-centent: space-eventy uk-navbar-nav
width: 50%
align-item:center 

banner margin-bottom:80px
*/

.default_img {
  width: 20vw !important;
  height: 95% !important;
  //aspect-ratio: 3/2;
  object-fit: contain;
}
.previous,
.next {
  color: $colorBlack !important;
}

.uk-navbar-container {
  background-color: $colorBlack !important;
  .nav_space {
    color: white !important;
    letter-spacing: $letterSpacing !important;
    font-weight: 700;
    font-size: 35px;
    padding: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.section_home_sentence {
  padding: 2vw 2vh;
  font-size: 17px;
}

.section_home_title {
  padding: 2vw 2vh;
}

.home_img {
  width: 25vw !important;
  height: 35vh !important;
}

.home_section_slide {
  margin: 2vh 0;
}

.about_container {
  margin-top: 100px;
}

.about_impair,
.about_pair {
  display: grid;
  padding: 10vh 3vw;
  margin: 4vh 5vw;
}

.about_impair {
  /* grid-template-columns: 30% 70%; */
  grid-template-columns: 40% 50%;
  align-items: center;
  justify-content: space-between;
  /* border-top:2px solid rgb(0 0 0 / 25%);
border-bottom: 2px solid rgb(0 0 0 / 25%); */
}

.about_pair {
  /*grid-template-columns: 70% 30%;*/
  grid-template-columns: 50% 40%;
  align-items: center;
  justify-content: space-between;
}

.contact_section {
  display: grid;
  grid-template-columns: 55% 45%;
  height: 75vh;
  padding: 2vw;
  margin-top: 10vh;
}

.contact_section_form {
  display: grid;
  margin: 2vh 0;
}

.contact_map,
.about_images {
  background-color: rgba($color: #f0eeee, $alpha: 0.5);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: $center;
  &_sentence {
    padding: 0px !important;
    margin: 5px 0px !important;
  }
}

.information {
  display: flex;
  width: 25%;
  justify-content: space-evenly;
  align-items: center;
  margin: 25px 0px 10px;
}

.legal_mention {
  color: $colorBlack;
}

/** Common**/

.section {
  height: 90px;
  display: flex;
  justify-content: $center;
  align-items: center;
  background-color: $colorBlack;
  padding: 20px !important;

  &_title {
    font-size: 60px;
    color: white;
    font-weight: 700;
    margin: 0px !important;
  }

  &_slide {
    margin: 2vh 0 !important;
  }
  &_slider {
    margin: 0px !important;
    display: flex;
    justify-content: center;
  }

  &_base {
    align-items: baseline;
  }
}

.socialNetwork {
  display: flex;
  width: 200px;
  justify-content: space-evenly;
}

.home_title {
  line-height: 1.2;
  font-size: 17px;
  font-weight: 400;

  letter-spacing: $letterSpacing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom: 1px solid;

  color: $colorBlack;
}
/*
.home_title,
.about_title,
.contact_title {
  line-height: 1.2;
  font-size: 30px;
  font-weight: 400;
  margin: 0 0 30px;
  letter-spacing: $letterSpacing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom: 1px solid;
  padding: 10px;
  color: $colorBlack;
}

*/
/* Media querie*/
/** impair : direction:rtl*/

// Small devices (landscape phones, 576px and up)

@media (min-width: 200px) {
  .nav_text {
    font-size: 20px;
  }

  *ul {
    padding: 0 !important;
  }
}

@media (max-width: 576px) {
  .section {
    height: 18px;

    &_title {
      font-size: 25px;
    }
  }

  .sentence {
    text-align: center !important;
  }

  .container_form {
    display: grid;
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .mention {
    height: 126px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact {
    &_section {
      display: flex;
      flex-direction: column;
      height: auto !important;
      margin-bottom: 20px !important;
      justify-content: center;
      margin-top: 3vh;
    }
    &_map {
      width: auto !important;
      height: 50vh !important;
      display: none;
    }
    &_google_map {
      display: none;
    }
  }

  .submit_button {
    width: 40% !important;
  }
  .about {
    &_impair {
      display: none;
    }
    &_pair {
      grid-template-columns: 100% !important;
      margin: 0 !important;
      padding: 3vh 3vw !important;
    }
  }

  .uk-navbar-container {
    //background-color: $colorBlack !important;
    margin-bottom: 20px !important;
  }
  .uk-navbar-nav {
    width: 100%;
  }
  .nav_space {
    font-size: 15px !important;
  }

  .uk-navbar-center {
    height: auto;
  }

  .default_img {
    width: 95% !important;
  }
  .information {
    width: 90% !important;

    border: 2px dashed green;
    a {
      font-size: 14px !important;
    }
  }

  .footer {
    &_sentence {
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .logo {
    height: auto;
    margin: 10px 0 !important;
    &_name {
      font-size: 62px !important;
      margin: 4px !important;
    }
  }

  .map_container {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .information {
    width: 95%;
    border: 3px dashed gray;
    a {
      font-size: 14px;
    }
  }

  .section {
    height: 30px;

    &_title {
      font-size: 40px;
    }
  }

  .about {
    &_impair {
      display: none;
    }
  }

  .uk-navbar-nav {
    width: 100%;
  }
  .nav_space {
    font-size: 14px !important;
  }
  .contact {
    &_section {
      display: flex;
      flex-direction: column;
      height: auto !important;
      margin-bottom: 20px !important;
      justify-content: center;
      margin-top: 3vh;
    }
  }
  .contact {
    &_google_map {
      display: none;
    }
  }
  .about {
    &_impair {
      display: none;
    }
    &_pair {
      grid-template-columns: 100% !important;
      margin: 0 !important;
      padding: 3vh 3vw !important;
    }
  }

  .logo {
    height: auto;
    margin: 5px 0 !important;
    &_name {
      font-size: 120px !important;
      margin: 44px !important;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 767px) and (max-width: 991px) {
  .information {
    width: 95%;
    border: 3px dashed purple;
    .gmap_canvas {
      overflow: hidden;
      background: none !important;
      width: 100% !important;
      height: 675px !important;
    }
    .gmap_iframe {
      height: 345px !important;
      border: 1px solid green;
    }
    // border: 3px dashed red;
    a {
      font-size: 14px;
    }
  }
  .mapouter {
    position: relative;
    text-align: right;
    height: 545px;
  }

  /* .about {
    &_impair,
    &_pair {
      grid-template-columns: 100% !important;
      margin: 0 !important;
      padding: 3vh 3vw !important;
      justify-items: center;
    }
  }*/

  .about {
    &_impair {
      display: none;
    }
    &_pair {
      grid-template-columns: 100% !important;
      margin: 0 !important;
      padding: 3vh 3vw !important;
    }
  }

  .nav_space {
    font-size: 30px !important;
  }

  .uk-navbar-nav {
    justify-content: space-evenly;
    width: 100%;
  }
  .contact {
    &_section {
      display: flex;
      flex-direction: column;
      height: auto !important;
      margin-bottom: 20px !important;
      justify-content: center;
      margin-top: 3vh;
    }
    &_map {
      width: 90%;
      height: 80%;
    }
  }

  .submit_button {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .uk-navbar-center {
    height: 100px;
  }
}

@media (min-width: 991px) and (max-width: 1015px) {
  .about {
    &_impair {
      display: none;
    }
    &_pair {
      grid-template-columns: 100% !important;
      margin: 0 !important;
      padding: 3vh 3vw !important;
    }
  }

  .information {
    width: 95%;
    border: 3px dashed blue;
    a {
      font-size: 14px;
    }
  }
  .submit_button {
    width: 50%;
  }
  .uk-navbar-nav {
    width: 90%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 1016px) and (max-width: 1200px) {
  .information {
    width: 95%;
    border: 3px dashed blue;
    a {
      font-size: 14px;
    }
  }
  .submit_button {
    width: 50%;
  }
  .uk-navbar-nav {
    width: 90%;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (max-width: 2000px) {
  .uk-navbar-nav {
    justify-content: space-evenly;
    width: 100%;
  }

  .contact_section {
  }

  .footer {
    margin-top: 7%;
  }

  .information {
    width: 70%;
    margin-top: 4%;
    // border: 3px dashed orange;

    a {
      font-size: 14px;
    }
  }
}

//ANIMATION

@keyframes typing {
  from {
    width: 0;
  }
  25% {
    width: 5%;
  }

  50% {
    width: 11%;
  }

  75% {
    width: 17%;
  }
  to {
    width: auto;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}
